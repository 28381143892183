import { TabBar } from "antd-mobile";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { AppOutline, UnorderedListOutline } from "antd-mobile-icons";

import styles from "./layout.module.less";

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value: string) => {
    navigate(value);
  };

  const tabs = [
    {
      key: "/home",
      title: "贷款计算",
      icon: <AppOutline />,
    },
    {
      key: "/prepayment",
      title: "提前还贷",
      icon: <UnorderedListOutline />,
    },
    // {
    //   key: "/message",
    //   title: "消息",
    //   icon: <MessageOutline />,
    // },
    // {
    //   key: "/me",
    //   title: "我的",
    //   icon: <UserOutline />,
    // },
  ];

  return (
    <div className={styles.app}>
      <div className={styles.body}>
        <Outlet />
      </div>
      <div className={styles.bottom}>
        <TabBar
          activeKey={pathname}
          onChange={(value) => setRouteActive(value)}
        >
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  );
}
