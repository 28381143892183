import Decimal from "decimal.js";
import { floor } from "../utils";

import styles from "./moneyValue.module.less";

export default function MoneyValue({ value }: { value?: Decimal }) {
  if (!value) {
    return <>0</>;
  }
  return (
    <div>
      {floor(value).toFixed(2)}
      <span className={styles.unit}>元</span>
    </div>
  );
}
