import {
  Input,
  CascadePicker,
  DatePicker,
  Radio,
  Space,
  Form,
  Button,
} from "antd-mobile";
import { DatePickerRef } from "antd-mobile/es/components/date-picker";
import Decimal from "decimal.js";
import { RefObject } from "react";
import { datePickerColumns } from "../constants/constants";
import { LoanData } from "../utils";
import dayjs from "dayjs";
import React from "react";

interface Props<T> {
  title: string;
  onSubmit: (data: LoanData & T) => void;
  onChange?: (data: LoanData) => void;
}

export default function LoanCalculatorForm<T>({
  title,
  children,
  onChange,
  onSubmit,
}: React.PropsWithChildren<Props<T>>) {
  const [form] = Form.useForm<
    {
      total: number;
      loanTime: ["year" | "month", string];
      startDate: Date;
      rate: number;
      type: LoanData["type"];
    } & T
  >();

  const submit = () => {
    return form
      .validateFields()
      .then((values) => ({
        ...values,
        total: new Decimal(values.total).mul(10000),
        rate: new Decimal(values.rate),
        loanTime: {
          type: values.loanTime[0],
          value: parseInt(values.loanTime[1]),
        },
      }))
      .then((v) => {
        onSubmit({
          ...v,
        });
      });
  };

  return (
    <div>
      <Form
        onValuesChange={(_, values: LoanData) => {
          onChange && onChange(values);
        }}
        form={form}
        initialValues={{
          total: 264,
          loanTime: ["year", "30"],
          startDate: dayjs("2021-04-01 00:00:00").toDate(),
          rate: 5.68,
          type: "equal-loan",
        }}
        layout="horizontal"
        footer={
          <Button color="primary" block onClick={submit}>
            计算
          </Button>
        }
      >
        <Form.Header>{title}</Form.Header>
        <Form.Item
          name="total"
          label="贷款金额"
          rules={[{ required: true, message: "贷款金额不能为空" }]}
          extra={<div>万</div>}
        >
          <Input placeholder="请输入总金额" type="number" />
        </Form.Item>
        <Form.Item
          name="loanTime"
          label="贷款时间"
          trigger="onConfirm"
          rules={[{ required: true, message: "贷款时长不能为空" }]}
          onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <CascadePicker options={datePickerColumns}>
            {([_, date]) => (date ? date.label : "请选择时长")}
          </CascadePicker>
        </Form.Item>
        <Form.Item
          required
          name="startDate"
          label="首次还款"
          trigger="onConfirm"
          rules={[{ required: true, message: "首次还款不能为空" }]}
          onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <DatePicker precision="month">
            {(value) => (value ? dayjs(value).format("YYYY-MM") : "请选择日期")}
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="rate"
          label="贷款利率"
          rules={[{ required: true, message: "贷款利率不能为空" }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item name="type" label="还款方式" required>
          <Radio.Group>
            <Space>
              <Radio value="equal-loan">等额本息</Radio>
              <Radio value="equal-principal">等额本金</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {children}
      </Form>
    </div>
  );
}
