import Decimal from "decimal.js";
import dayjs from "dayjs";
import Annuity from "./annuity";
import { LoanCalculator } from "./LoanCalculator";
import { PrePaymentType, PrePaymentData } from "./index";
import Linear from "./linear";

export abstract class PrePaymentCalculator {
  protected _prePayment: Decimal;
  protected _date: dayjs.Dayjs;
  protected _type: PrePaymentType;
  protected _rate: Decimal;
  protected _month: number;
  /**
   * before loan
   */
  protected _loan: LoanCalculator;

  readonly afterLoan: LoanCalculator;

  constructor({
    date,
    total,
    rate,
    type,
    loan,
  }: PrePaymentData & { loan: LoanCalculator }) {
    this._prePayment = new Decimal(total).mul(10000);
    this._date = dayjs(date);
    this._type = type;
    this._rate = new Decimal(rate).div(12).div(100);
    this._loan = loan;

    this._month = PrePaymentCalculator.floorMonth(
      this.calculateAfterMonth()
    ).toNumber();

    this.afterLoan =
      loan.type === "equal-loan"
        ? new Annuity({
            rate: new Decimal(rate),
            loanTime: {
              type: "month",
              value: this._month,
            },
            startDate: date,
            total: this.leftBalance.minus(this.prePayment),
            type: loan.type,
          })
        : new Linear({
            rate: new Decimal(rate),
            loanTime: {
              type: "month",
              value: this._month,
            },
            startDate: date,
            total: this.leftBalance.minus(this.prePayment),
            type: loan.type,
          });
  }

  get rate() {
    return this._rate;
  }

  private static floorMonth(month: Decimal): Decimal {
    const v = new Decimal(month.toFixed(0));
    if (month.equals(v)) {
      return month;
    }
    return v.plus(1);
  }

  private calculateAfterMonth(): Decimal {
    if (this._type === "equal-date") {
      return new Decimal(this.loan.month).minus(this.payedMonth);
    }
    if (this.loan.type === "equal-loan") {
      return PrePaymentCalculator.calculateMonthByPayments(
        this.leftBalance.minus(this.prePayment),
        this._rate,
        (this.loan as Annuity).monthPayment()
      );
    }
    // 根据第一个月金额计算
    return PrePaymentCalculator.calculateMonthByFirstMonthWithLiner(
      this.leftBalance.minus(this.prePayment),
      this._rate,
      this.loan.monthDetail(1).payment.plus(this.loan.monthDetail(1).interest)
    );
  }

  /**
   * 计算等额本金的还款时间
   * @param total
   * @param rate
   * @param firstMonthPayment
   * @returns
   */
  private static calculateMonthByFirstMonthWithLiner(
    total: Decimal,
    rate: Decimal,
    firstMonthPayment: Decimal
  ) {
    console.log(total.toString());
    return total.div(firstMonthPayment.minus(total.mul(rate)));
  }

  /**
   * 计算等额本息的还款时间
   * @param total
   * @param rate
   * @param payments
   * @returns
   */
  private static calculateMonthByPayments(
    total: Decimal,
    rate: Decimal,
    payments: Decimal
  ) {
    const v = Decimal.div(1, Decimal.sub(1, rate.mul(total).div(payments)));
    return Decimal.log(v, rate.plus(1));
  }

  /**
   * 本次提前还款后剩余的利息
   */
  abstract get interest(): Decimal;

  /**
   * 本次提前还款后剩余的本金
   */
  abstract get balance(): Decimal;

  /**
   * 本次提前还款后剩余的月数
   */
  get month(): Decimal {
    return new Decimal(this._month);
  }

  /**
   * 提前还款的金额
   */
  get prePayment(): Decimal {
    return this._prePayment;
  }

  /**
   * 提前还款的日期
   */
  get date(): dayjs.Dayjs {
    return this._date;
  }

  /**
   * 本次还款前的贷款计算器
   */
  get loan(): LoanCalculator {
    return this._loan;
  }

  /**
   * 距离提前还款已经还的月数，含当月
   */
  get payedMonth(): number {
    return this.date.diff(this.loan.date, "month") + 1;
  }

  /**
   * 已经还了的利息 = 已经还了的每个月的利息相加
   */
  get payedInterest(): Decimal {
    return new Array(this.payedMonth)
      .fill(0)
      .map((_, idx) => this.loan.monthDetail(idx + 1))
      .reduce((prev, cur) => cur.interest.plus(prev), new Decimal(0));
  }

  /**
   * 已经还了的本金 = 已经还了的每个月的本金相加
   */
  get payedPayment(): Decimal {
    return new Array(this.payedMonth)
      .fill(0)
      .map((_, idx) => this.loan.monthDetail(idx + 1))
      .reduce((prev, cur) => cur.payment.plus(prev), new Decimal(0));
  }

  /**
   * 剩余的利息 = 总共利息 - 已经还了的利息
   */
  get leftInterest(): Decimal {
    return this.loan.totalInterest().minus(this.payedInterest);
  }

  /**
   * 剩余本金 = 总共本金 - 已经还了的本金
   */
  get leftBalance(): Decimal {
    return this.loan.total.minus(this.payedPayment);
  }
}
