import { Collapse, ErrorBlock, List } from "antd-mobile";
import MoneyValue from "../../component/MoneyValue";
import { LoanData, PrePaymentType } from "../../utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import EqualPaymentPrePayment from "../../utils/equal-payment";
import Annuity from "../../utils/annuity";
import { useCallback, useMemo } from "react";
import { PrePaymentCalculator } from "../../utils/PrePaymentCalculator";
import EqualDatePrePayment from "../../utils/equal-date";
import Linear from "../../utils/linear";
import { LoanCalculator } from "../../utils/LoanCalculator";

dayjs.extend(relativeTime);

export type Data = LoanData & {
  prePayments?: {
    date: Date;
    total: string;
    rate: string;
    type: PrePaymentType;
  }[];
};

function PrePaymentV2({ calculator }: { calculator: PrePaymentCalculator }) {
  const renderLoan = (loan: LoanCalculator) => {
    if (loan.type === "equal-loan") {
      return (
        <List.Item
          extra={<MoneyValue value={(loan as Annuity).monthPayment()} />}
        >
          每月还款
        </List.Item>
      );
    }
    const { payment, interest } = (loan as Linear).monthDetail(1);
    return (
      <>
        <List.Item
          extra={<MoneyValue value={(loan as Linear).monthDecrease} />}
        >
          每月递减
        </List.Item>
        <List.Item extra={<MoneyValue value={payment.plus(interest)} />}>
          首月还款
        </List.Item>
      </>
    );
  };
  const beforeDetail = useMemo(() => {
    return renderLoan(calculator.loan);
  }, [calculator]);

  const afterDetail = useMemo(() => {
    return renderLoan(calculator.afterLoan);
  }, [calculator]);

  return (
    <>
      <List header={<span>提前还款前</span>}>
        {beforeDetail}
        <List.Item extra={`${calculator.payedMonth} 月`}>已还月数</List.Item>
        <List.Item extra={<MoneyValue value={calculator.payedInterest} />}>
          已还利息
        </List.Item>
        <List.Item extra={<MoneyValue value={calculator.payedPayment} />}>
          已还本金
        </List.Item>
        <List.Item extra={<MoneyValue value={calculator.leftInterest} />}>
          剩余利息
        </List.Item>
        <List.Item extra={<MoneyValue value={calculator.leftBalance} />}>
          剩余本金
        </List.Item>
      </List>
      <List
        header={<span>提前还款 {calculator.prePayment.toFixed(2)} 元后</span>}
      >
        <List.Item
          extra={
            <MoneyValue
              value={calculator.leftInterest.minus(
                calculator.afterLoan.totalInterest()
              )}
            />
          }
        >
          节省利息
        </List.Item>
        {afterDetail}
        <List.Item extra={`${calculator.afterLoan.month} 月`}>
          贷款期限
        </List.Item>
        <List.Item extra={<MoneyValue value={calculator.afterLoan.total} />}>
          贷款金额
        </List.Item>
        <List.Item extra={`${calculator.afterLoan.rate.mul(100).mul(12)}%`}>
          贷款利率
        </List.Item>
        <List.Item
          extra={<MoneyValue value={calculator.afterLoan.totalInterest()} />}
        >
          利息总额
        </List.Item>
      </List>
    </>
  );
}

export default function Result({ data }: { data: Data }) {
  const newLoan = useCallback(
    (values: Omit<LoanData, "type">) => {
      if (data.type === "equal-loan") {
        return new Annuity({ ...values, type: "equal-loan" });
      }
      return new Linear({ ...values, type: "equal-principal" });
    },
    [data.type]
  );

  const result = useMemo(
    () =>
      data?.prePayments?.reduce<PrePaymentCalculator[]>((prev, cur) => {
        const loan =
          prev.length === 0 ? newLoan(data) : prev[prev.length - 1].afterLoan;
        return [
          ...prev,
          cur.type === "equal-payment"
            ? new EqualPaymentPrePayment({ ...cur, loan })
            : new EqualDatePrePayment({ ...cur, loan }),
        ];
      }, []),
    [data, newLoan]
  );

  if (!data.prePayments?.length) {
    return <ErrorBlock status="empty" title={"没有数据可以计算"} />;
  }

  return (
    <div>
      <Collapse>
        {result?.map((calculator, idx) => (
          <Collapse.Panel key={`${idx}`} title={`第 ${idx + 1} 次还款`}>
            <PrePaymentV2 calculator={calculator} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
}
