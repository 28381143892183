import { Collapse, List, Space } from "antd-mobile";
import { useEffect, useState } from "react";
import MoneyValue from "../../component/MoneyValue";
import { calculateLoan, LoanData } from "../../utils";
import Annuity from "../../utils/annuity";
import Linear from "../../utils/linear";
import { LoanCalculator } from "../../utils/LoanCalculator";

import styles from "./result.module.less";

type MonthDetailProps = ReturnType<typeof calculateLoan>["monthDetail"][0];

const MonthDetail = ({
  date,
  interest,
  payment,
  left,
  idx,
}: MonthDetailProps & { idx: number }) => {
  return (
    <Space>
      <Space direction="horizontal">
        <span>{idx < 10 ? `00${idx}` : idx < 100 ? `0${idx}` : idx}</span>
        <span>{date.format("YYYY-MM")}</span>
        <span>{payment.toFixed(2).toString()}</span>
        <span>{interest.toFixed(2).toString()}</span>
        <span>{left.toFixed(2).toString()}</span>
      </Space>
    </Space>
  );
};

export default function Result({ data }: { data: LoanData }) {
  const [calculator, setCalculator] = useState<LoanCalculator>();

  useEffect(() => {
    if (data.type === "equal-loan") {
      setCalculator(new Annuity(data));
    } else {
      setCalculator(new Linear(data));
    }
  }, [data, data.type, setCalculator]);

  if (!calculator) {
    return <></>;
  }

  return (
    <div>
      <Collapse defaultActiveKey={["total"]}>
        <Collapse.Panel key="total" title="总览">
          <List>
            <List.Item extra={<MoneyValue value={calculator.total} />}>
              还款总额
            </List.Item>
            <List.Item
              extra={<MoneyValue value={calculator.totalInterest()} />}
            >
              利息总额
            </List.Item>
            {calculator.type === "equal-loan" ? (
              <List.Item
                extra={
                  <MoneyValue value={(calculator as Annuity).monthPayment()} />
                }
              >
                每月还款
              </List.Item>
            ) : (
              <List.Item
                extra={
                  <MoneyValue value={(calculator as Linear).monthDecrease} />
                }
              >
                每月递减
              </List.Item>
            )}
          </List>
        </Collapse.Panel>
        <Collapse.Panel key="detail" className={styles.detail} title="明细">
          <List>
            {new Array(calculator.month.toNumber()).fill(0)?.map((_, idx) => {
              const value = calculator.monthDetail(idx + 1);
              return (
                <List.Item key={idx}>
                  <MonthDetail
                    idx={idx + 1}
                    date={value.date}
                    interest={value.interest}
                    payment={value.payment}
                    left={value.balance}
                  />
                </List.Item>
              );
            })}
          </List>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
