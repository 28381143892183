import { createBrowserRouter } from "react-router-dom";
import Layout from "./component/Layout";
import HouseLoanCalculator from "./pages/house-loan-calculator";
import Prepayment from "./pages/prepayment-calculator";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "home",
        element: <HouseLoanCalculator />,
      },
      {
        path: "prepayment",
        element: <Prepayment />,
      },
    ],
  },
  {},
]);

export default router;
