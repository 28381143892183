import Decimal from "decimal.js";
import { PrePaymentCalculator } from "./PrePaymentCalculator";

export default class EqualPaymentPrePayment extends PrePaymentCalculator {
  /**
   * 本次提前还款后的利息计算
   */
  get interest(): Decimal {
    return this.afterLoan.totalInterest();
  }

  /**
   * 本次提前还款后剩余的本金 = 剩余本金 - 提前还款金额
   */
  get balance(): Decimal {
    return this.leftBalance.minus(this._prePayment);
  }
}
