import Decimal from "decimal.js";
import { MonthDetail } from ".";
import { LoanCalculator } from "./LoanCalculator";

export default class Linear extends LoanCalculator {
  totalInterest(): Decimal {
    return this.month.mul(
      this.total
        .mul(this.rate)
        .minus(
          this.rate.mul(
            this.total.div(this.month).mul(this.month.minus(1).div(2))
          )
        )
    );
  }

  monthDetail(month: Decimal.Value): MonthDetail {
    if (new Decimal(month).lessThanOrEqualTo(0)) {
      throw new Error("month should more than 0");
    }
    // 每月本金
    const monthPrincipal = this.total.div(this.month);

    // 已经还了的本金 = 每月本金 * 月数
    const payedTotal = new Decimal(month).minus(1).mul(monthPrincipal);

    // 剩余本金
    const balance = this.total.minus(payedTotal);

    // 当前月已经还的金额
    const interest = balance.mul(this.rate);
    return {
      interest,
      balance: balance.minus(monthPrincipal),
      payment: monthPrincipal,
      date: this.currentDate(month),
    };
  }

  /**
   * 每月递减递减的利息
   */
  get monthDecrease(): Decimal {
    return this.monthDetail(this.month).interest;
  }
}
