import Decimal from "decimal.js";
import dayjs from "dayjs";

export interface LoanData {
  total: Decimal;
  loanTime: { type: "year" | "month"; value: number };
  startDate: Date;
  rate: Decimal;
  type: "equal-loan" | "equal-principal";
}

export type PrePaymentType = "equal-payment" | "equal-date";

export interface PrePaymentData {
  date: Date;
  total: string;
  rate: string;
  type: PrePaymentType;
}

export interface MonthDetail {
  date: dayjs.Dayjs;
  // 每月本金
  payment: Decimal;
  // 每月利息
  interest: Decimal;
  // 剩余本金
  balance: Decimal;
}

/**
 * 等额本息计算每个月的利息
 * @param total
 * @param currentMonth
 * @param monthPayments
 * @param rate
 * @returns
 */
export function calculateMonthInterest(
  total: Decimal,
  currentMonth: Decimal.Value,
  monthPayments: Decimal,
  rate: Decimal
): { interest: Decimal; payment: Decimal; left: Decimal }[] {
  const r = rate.div(12).div(100);
  if (new Decimal(0).equals(currentMonth)) {
    const interest = floor(total.mul(r));
    const payment = monthPayments.minus(interest);
    return [
      {
        interest, // 每月利息
        payment, // 每月实际还款
        left: total.minus(payment), // 剩余贷款金额
      },
    ];
  }
  const prev = calculateMonthInterest(
    total,
    Decimal.sub(currentMonth, 1),
    monthPayments,
    rate
  );

  const interest = prev[prev.length - 1].left.mul(r);
  const payment = monthPayments.minus(interest);

  return [
    ...prev,
    {
      interest: interest,
      payment: payment,
      left: prev[prev.length - 1].left.minus(payment),
    },
  ];
}

/**
 * 计算利息
 */
export function calculateLoan({
  total,
  loanTime,
  startDate,
  rate,
  type,
}: LoanData) {
  const month =
    loanTime.type === "month" ? loanTime.value : loanTime.value * 12;
  //   if (type === "equal-loan") {
  const monthPayments = calculateEqualLoanMonthPayments(total, rate, month);
  return {
    monthPayments: floor(monthPayments),
    total: directFloor(monthPayments.mul(month)),
    interest: directFloor(monthPayments.mul(month).minus(total)),
    monthDetail: calculateMonthInterest(
      total,
      month - 1,
      monthPayments,
      rate
    ).map((item, idx) => ({
      ...item,
      date: dayjs(startDate).add(idx, "M"),
    })),
  } as const;
  //   }
}

export function floor(value: Decimal): Decimal {
  return value.mul(100).round().div(100);
}

function directFloor(value: Decimal): Decimal {
  return floor(value);
}

/**
 * 计算等额本息每月还款
 * @param total
 * @param rate
 * @param month
 * @returns
 */
export function calculateEqualLoanMonthPayments(
  total: Decimal,
  rate: Decimal,
  month: Decimal.Value
) {
  const monthRate = rate.div(12).div(100);
  return total.mul(
    monthRate
      .mul(monthRate.plus(1).pow(month))
      .div(monthRate.plus(1).pow(month).minus(1))
  );
}

/**
 * 计算等额本息需要的还款月数
 * @param total
 * @param rate
 * @param payments 等额本息每月还款额
 */
export function calculateMonthByPayments(
  total: Decimal,
  rate: Decimal,
  payments: Decimal
) {
  const monthRate = rate.div(12).div(100);
  const v = Decimal.div(1, Decimal.sub(1, monthRate.mul(total).div(payments)));
  return Decimal.log(v, monthRate.plus(1));
}
