/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Form, Input, Radio, Space } from "antd-mobile";
import DatePicker, {
  DatePickerRef,
} from "antd-mobile/es/components/date-picker";
import { RefObject, useState } from "react";
import LoanCalculatorForm from "../../component/LoanCalculatorForm";
import { useTitle } from "../../hooks";
import styles from "./index.module.less";
import dayjs from "dayjs";
import { AddCircleOutline } from "antd-mobile-icons";
import { FormInstance } from "antd-mobile/es/components/form";
import Decimal from "decimal.js";
import Result, { Data } from "./Result";

function PrepaymentForm({ idx }: { idx: number }) {
  return (
    <>
      <Form.Item
        required
        name={[idx, "date"]}
        label="还款日期"
        trigger="onConfirm"
        rules={[
          { required: true, message: "还款日期不能为空" },
          (form: FormInstance) => {
            return {
              validator(rule, value) {
                let preDate = form.getFieldValue("startDate");
                if (idx > 0) {
                  const prePayments = form.getFieldValue("prePayments");
                  const prev = prePayments[idx - 1];
                  if (prev?.date) {
                    preDate = prev.date;
                  }
                }
                if (preDate) {
                  const currentDate = dayjs(value);
                  if (currentDate.isBefore(preDate)) {
                    return Promise.reject(
                      idx === 0
                        ? `还款日期不能早于首次还款日期`
                        : `还款日期不能早于第 ${idx} 次`
                    );
                  }
                }
                return Promise.resolve();
              },
            };
          },
        ]}
        onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
          datePickerRef.current?.open();
        }}
      >
        <DatePicker precision="month">
          {(value) => (value ? dayjs(value).format("YYYY-MM") : "请选择日期")}
        </DatePicker>
      </Form.Item>
      <Form.Item
        name={[idx, "rate"]}
        label="贷款利率"
        rules={[{ required: true, message: "贷款利率不能为空" }]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name={[idx, "total"]}
        label="还款金额"
        rules={[
          { required: true, message: "还款金额不能为空" },
          (form: FormInstance) => {
            return {
              validator: (rule, value, callback) => {
                const total = form.getFieldValue("total");
                const prePayments: Decimal = form
                  .getFieldValue("prePayments")
                  .map((item?: { total: number }) => item?.total)
                  .filter((item?: number) => item)
                  .map((item: number) => new Decimal(item))
                  .reduce((prev: Decimal.Value, current: Decimal) => {
                    return current.plus(prev);
                  }, new Decimal(0));
                if (!prePayments?.lessThanOrEqualTo(total)) {
                  return Promise.reject("还款金额不能超过总金额");
                }
                return Promise.resolve();
              },
            };
          },
        ]}
        extra={<div>万</div>}
      >
        <Input placeholder="输入还款金额" type="number" />
      </Form.Item>
      <Form.Item name={[idx, "type"]} label="还款方案" required>
        <Radio.Group>
          <Space>
            <Radio value="equal-date">期限不变</Radio>
            <Radio value="equal-payment">月供不变</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

export default function Prepayment() {
  useTitle("提前还款计算器");

  const [data, setData] = useState<Data>();

  const [loanRate, setLoanRate] = useState<string>("5.68");

  return (
    <div className={styles.container}>
      <LoanCalculatorForm
        title="提前还贷计算器"
        onSubmit={setData}
        onChange={({ rate }) => {
          setLoanRate(rate.toString());
        }}
      >
        <Form.Array
          name="prePayments"
          onAdd={(operation) => {
            operation.add({ type: "equal-payment", rate: loanRate });
          }}
          renderAdd={() => (
            <span>
              <AddCircleOutline /> 添加
            </span>
          )}
          renderHeader={({ index }, { remove }) => (
            <>
              <span>第 {index + 1} 还款</span>
              <a onClick={() => remove(index)} style={{ float: "right" }}>
                删除
              </a>
            </>
          )}
        >
          {(fields) =>
            fields.map(({ index: idx }) => <PrepaymentForm idx={idx} />)
          }
        </Form.Array>
      </LoanCalculatorForm>
      {data && <Result data={data} />}
    </div>
  );
}
