export const datePickerColumns = [
  {
    label: "按年",
    value: "year",
    children: new Array(30)
      .fill(0)
      .map((_, idx) => idx + 1)
      .sort((a, b) => (a > b ? -1 : 1))
      .map((item) => ({
        label: `${item} 年`,
        value: `${item}`,
      })),
  },
  {
    label: "按月",
    value: "month",
    children: new Array(30 * 12)
      .fill(0)
      .map((_, idx) => idx + 1)
      .sort((a, b) => (a > b ? -1 : 1))
      .map((item) => ({
        label: `${item} 月`,
        value: `${item}`,
      })),
  },
];
