import React, { useState } from "react";
import { useTitle } from "../../hooks";

import styles from "./index.module.less";
import Result from "./Result";
import { LoanData } from "../../utils";
import LoanCalculatorForm from "../../component/LoanCalculatorForm";

export default function HouseLoanCalculator() {
  useTitle("房贷计算");

  const [data, setData] = useState<LoanData>();

  return (
    <div className={styles.container}>
      <LoanCalculatorForm
        title="房贷计算器"
        onSubmit={(v) => {
          setData(v);
        }}
      />
      {data && <Result data={data} />}
    </div>
  );
}
