import Decimal from "decimal.js";
import { MonthDetail, floor } from "./index";
import { LoanCalculator } from "./LoanCalculator";

/**
 * 等额本息
 */

export default class Annuity extends LoanCalculator {
  private _monthPayments?: Decimal;
  private monthDetailCache?: (MonthDetail | undefined)[];

  monthDetail(month: Decimal.Value): MonthDetail {
    if (new Decimal(month).lessThanOrEqualTo(0)) {
      throw new Error("month should more than 0");
    }

    if (!this.monthDetailCache) {
      this.monthDetailCache = new Array<MonthDetail | undefined>(
        this.month.toNumber()
      ).fill(undefined);
    }
    const index = new Decimal(month).toNumber();
    let detail = this.monthDetailCache[index - 1];
    if (detail) {
      return detail;
    }
    detail = this._monthDetail(month);
    this.monthDetailCache[index - 1] = detail;
    return detail;
  }

  private _monthDetail(month: Decimal.Value): MonthDetail {
    if (new Decimal(1).equals(month)) {
      const interest = floor(this.total.mul(this.rate));
      const payment = this.monthPayment().minus(interest);
      return {
        interest,
        payment,
        balance: this.total.minus(payment),
        date: this.currentDate(month),
      };
    }
    const prev = this._monthDetail(Decimal.sub(month, 1));
    const interest = prev.balance.mul(this.rate);
    const payment = this.monthPayment().minus(interest);
    return {
      interest,
      payment,
      balance: prev.balance.minus(payment),
      date: this.currentDate(month),
    };
  }

  public totalInterest(): Decimal {
    return this.monthPayment().mul(this.month).minus(this.total);
  }

  /**
   * 等额本息每月还款
   * @returns
   */
  public monthPayment(): Decimal {
    if (!this._monthPayments) {
      this._monthPayments = this.total.mul(
        this.rate
          .mul(this.rate.plus(1).pow(this.month))
          .div(this.rate.plus(1).pow(this.month).minus(1))
      );
    }
    return this._monthPayments;
  }
}
