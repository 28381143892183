import Decimal from "decimal.js";
import dayjs from "dayjs";
import { LoanData, MonthDetail } from "./index";

export abstract class LoanCalculator {
  protected _total: Decimal;
  protected _month: Decimal;
  protected startDate: dayjs.Dayjs;
  readonly rate: Decimal;
  readonly type: LoanData["type"];

  constructor(data: LoanData) {
    this._total = data.total;
    this._month = new Decimal(
      data.loanTime.type === "month"
        ? data.loanTime.value
        : data.loanTime.value * 12
    );
    this.startDate = dayjs(data.startDate);
    this.rate = data.rate.div(12).div(100);
    this.type = data.type;
  }

  /**
   * 开始还款的日期
   */
  get date(): dayjs.Dayjs {
    return this.startDate;
  }

  protected currentDate(month: Decimal.Value) {
    return this.startDate.add(Decimal.sub(month, 1).toNumber(), "month");
  }

  /**
   * 总共利息
   */
  abstract totalInterest(): Decimal;
  /**
   * 总共月数
   */
  get month(): Decimal {
    return this._month;
  }
  /**
   * 总共贷款金额
   */
  get total(): Decimal {
    return this._total;
  }

  /**
   * 第 month 月的还款额
   * @param month
   */
  abstract monthDetail(month: Decimal.Value): MonthDetail;
}
